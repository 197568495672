<script lang="ts" setup>
import { twJoin } from "tailwind-merge";
import type { PxlIcon, PxlIconName } from "@/common/components/U/Icon";
import { iconBind } from "@/common/components/U/Icon";

const colors = {
  blue: "u-info-blue",
  yellow: "u-info-yellow",
};
const color_icons_map: Record<NonNullable<keyof typeof colors>, PxlIconName> = {
  blue: "info",
  yellow: "warning",
};

const emit = defineEmits(["close"]);
const props = withDefaults(
  defineProps<{
    text?: string;
    closable?: boolean;
    icon?: PxlIcon;
    color?: keyof typeof colors;
  }>(),
  {
    color: "blue",
  },
);
const infoClass = computed(() => twJoin("u-info", colors[props.color]));
const infoIcon = computed(() => props.icon || color_icons_map[props.color]);
</script>

<template>
  <div :class="infoClass">
    <UIcon
      v-if="infoIcon"
      v-bind="iconBind(infoIcon, { class: 'text-current' })"
    />
    <div class="shrink grow basis-0 text-sm font-medium leading-[1.188rem] text-black dark:text-white">
      {{ props.text }}

      <slot />
    </div>
    <div class="flex size-4 items-center justify-center">
      <UButton
        v-if="props.closable"
        title="Close"
        :padded="false"
        variant="clean"
        icon="close"
        @click="emit('close')"
      />
    </div>
  </div>
</template>
